@font-face {
    font-family: 'Replica';
    src: url('./Replica Regular.eot');
    src: local('☺'), url('./Replica Regular.woff') format('woff'),
        url('./Replica Regular.ttf') format('truetype'),
        url('./Replica Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Replica';
    src: url('./Replica Bold.eot');
    src: local('☺'), url('./Replica Bold.woff') format('woff'),
        url('./Replica Bold.ttf') format('truetype'),
        url('./Replica Bold.svg') format('svg');
    font-weight: 700;
    font-style: normal;
}
